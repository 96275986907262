import { Extension } from "@tiptap/core";
import { VDocumentFragment, VHTMLDocument, parseHTML } from "zeed-dom";
import { getHtmlFromContent } from "./getHtmlFromContent";

export const getPlainTextFromContent = ({
    content,
    type,
}: {
    content: string;
    type: "note" | "flashcard";
    extensions?: Extension[];
}): string => {
    const htmlInput = getHtmlFromContent({ content, type });
    return htmlToPlainText(htmlInput);
};

const htmlToPlainText = (html: string) => {
    const dom = parseHTML(html);
    try {
        return getRecursiveNestedContent(dom);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error("HTML PARSING ERROR", e);
        return dom.textContent ?? "";
    }
};

export const getRecursiveNestedContent = (node: Node | VHTMLDocument | VDocumentFragment): string => {
    const children = Array.from(node.childNodes);
    const tagName = (node as Element).tagName;

    if (children.length === 0) {
        if (tagName === "BR") return "\n";
        return node.textContent ?? "";
    }

    const needsNewLine =
        tagName !== "P" &&
        tagName !== "H1" &&
        tagName !== "H2" &&
        tagName !== "H3" &&
        tagName !== "H4" &&
        tagName !== "H5" &&
        tagName !== "H6" &&
        tagName !== "BR";

    return children.map(getRecursiveNestedContent).join(needsNewLine ? "\n" : "");
};
