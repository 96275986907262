import React, { forwardRef } from "react";
import MuiIconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { LucideIcon, LucideProps } from "lucide-react";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import { themeColors } from "@/utils/themeColors";

const TooltipWrapper = ({
    children,
    tooltip,
    disabled,
}: {
    children: React.ReactElement;
    tooltip: string;
    disabled: boolean;
}) => {
    if (disabled || !tooltip) {
        return children;
    }

    return (
        <Tooltip
            title={tooltip}
            TransitionComponent={Zoom}
            arrow
            slotProps={{ tooltip: { sx: { color: "#514E6A", backgroundColor: "#ffff", fontSize: "1rem" } } }}>
            {children}
        </Tooltip>
    );
};

type IconButtonProps = {
    tooltip: string;
    onClick: () => void;
    Icon: LucideIcon;
    iconProps?: Partial<LucideProps>;
    isActive?: boolean;
    disabled?: boolean;
};

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
    ({ tooltip, disabled = false, onClick, isActive = false, Icon, iconProps }, ref) => {
        const { mdDown } = useBreakPoints({ md: 1000 });

        return (
            <TooltipWrapper tooltip={tooltip} disabled={disabled}>
                <MuiIconButton
                    ref={ref}
                    disabled={disabled}
                    onClick={onClick}
                    style={{ opacity: disabled ? 0.4 : 1 }}
                    size={mdDown ? "small" : "medium"}>
                    {Icon && (
                        <Icon
                            size={mdDown ? iconSizes.XS : iconSizes.SM_S}
                            color={themeColors.neutralBlack}
                            strokeWidth={isActive ? strokeWidth.bold : strokeWidth.normal}
                            {...iconProps}
                        />
                    )}
                </MuiIconButton>
            </TooltipWrapper>
        );
    }
);

IconButton.displayName = "IconButton";

export default IconButton;
