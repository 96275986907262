import { getDisplayedLanguageText } from "@knowt/syncing/hooks/flashcards/languageUtils";
import Image from "next/image";
import { useState } from "react";
import Dialog from "@/components/Dialog";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import SlideMenu from "@/components/SlideMenu";
import CircularButton from "@/components/CircularButton";
import ClickableText from "@/components/styled/button/ClickableText";
import RoundInput from "@/components/styled/input/RoundInput";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import { useTheme } from "@/utils/theme";
import { themeColors } from "@/utils/themeColors";
import { ASSETS_URL } from "@/config/deployConstants";

const xImage = `${ASSETS_URL}/images/X-bold-black.png`;
const xImageDark = `${ASSETS_URL}/images/X-bold-white.png`;

type ChooseLanguagePopupProps = {
    isOpen: boolean;
    onClose: () => void;
    listOfLanguages: string[];
    onSelectLanguage: (lang: string) => void;
    currSelectedLanguage: string;
};

const ChooseLanguagePopup = ({
    isOpen,
    onClose: _onClose,
    listOfLanguages: _listOfLanguages = [],
    onSelectLanguage,
    currSelectedLanguage,
}: ChooseLanguagePopupProps) => {
    const { smDown } = useBreakPoints();
    const { isDarkMode } = useTheme();
    const [searchText, setSearchText] = useState("");

    const onClose = () => {
        setSearchText("");
        _onClose();
    };

    const listOfLanguages = _listOfLanguages.filter(a => a.toLowerCase().includes(searchText.toLowerCase()));

    const renderContent = () => {
        return (
            <>
                <FlexRowAlignCenter style={{ gap: "2rem" }}>
                    <CircularButton
                        onClick={onClose}
                        sx={{
                            border: "2px solid #eeeeee",
                            backgroundColor: themeColors.background,
                        }}
                        radius="4.2rem">
                        <Image
                            src={isDarkMode ? xImageDark : xImage}
                            height="20"
                            width="20"
                            style={{ objectFit: "contain" }}
                            alt="close"
                        />
                    </CircularButton>
                    <span style={{ color: themeColors.neutralBlack, fontWeight: "600", fontSize: "2.5rem" }}>
                        choose a language
                    </span>
                </FlexRowAlignCenter>
                <RoundInput
                    className="knowtFontBold"
                    autoFocus={true}
                    placeholder={"Search for a language"}
                    value={searchText}
                    onChange={event => setSearchText(event.target.value)}
                    style={{
                        height: "5rem",
                        fontSize: "1.8rem",
                        background: `${
                            themeColors.searchBar
                        } url(${`${ASSETS_URL}/images/Search-Icon.svg`}) 18px center / 16px 16px no-repeat`,
                        paddingLeft: "45px",
                    }}
                />
                <FlexColumn
                    style={{
                        gap: "1.5rem",
                        flex: 1,
                        overflowY: "scroll",
                        marginBottom: "-4rem",
                        paddingBottom: "2rem",
                    }}>
                    {listOfLanguages.map(language => {
                        return (
                            <ClickableText
                                key={language}
                                style={{
                                    fontSize: "2rem",
                                    color: currSelectedLanguage === language ? "#58d4c5" : undefined,
                                }}
                                onClick={e => {
                                    e.stopPropagation();
                                    onSelectLanguage(language);
                                }}>
                                {getDisplayedLanguageText(language)}
                            </ClickableText>
                        );
                    })}
                </FlexColumn>
            </>
        );
    };

    const renderForDesktop = () => {
        return (
            <Dialog
                TransitionProps={{ unmountOnExit: true }}
                open={isOpen}
                onClose={onClose}
                maxWidth={false}
                PaperComponent={FlexColumn}
                PaperProps={{
                    style: {
                        backgroundColor: themeColors.background,
                        borderRadius: "2rem",
                        padding: "4rem",
                        gap: "2.6rem",
                        minWidth: "52rem",
                        height: "65rem",
                    },
                }}>
                {renderContent()}
            </Dialog>
        );
    };

    const renderForMobile = () => {
        return (
            <SlideMenu
                isOpen={isOpen}
                onClose={onClose}
                onClickAway={() => null}
                style={{
                    backgroundColor: themeColors.background,
                }}>
                <FlexColumn style={{ padding: "2.47rem 2rem 2rem 2rem", gap: "2.6rem" }}>{renderContent()}</FlexColumn>
            </SlideMenu>
        );
    };

    return !smDown ? renderForDesktop() : renderForMobile();
};

export default ChooseLanguagePopup;
