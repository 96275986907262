import { AICompletionType, FlashcardSide, Language } from "@knowt/syncing/graphql/schema";
import { AIPrompt, AiEditorMutationType } from "@/components/flashcards/FlashcardCard/EditableFlashcardCard/constants";
import { AILocalOutputType } from "@/components/flashcards/FlashcardCard/EditableFlashcardCard/utils";
import { Ref } from "react";

export type GetAIResponseFromPromptType = ({
    side,
    selectedText,
    input,
    prompt,
    targetLanguage,
}: {
    input: string;
    selectedText?: string;
    side?: FlashcardSide;
    prompt: AIPrompt;
    targetLanguage?: string;
}) => Promise<AILocalOutputType>;

export type EditorFlashcard = {
    side: FlashcardSide;
    term: {
        ref: Ref<unknown>;
        content: string;
        language: Language;
    };
    definition: {
        ref: Ref<unknown>;
        content: string;
        language: Language;
    };
    onChooseLanguage: (selectedLanguage: Language) => void;
};

export type PosStyle = {
    left?: number;
    width?: number;
    top: number;
    markdownTextComponentHeight?: number;
};

export const NOTE_HIGHLIGHT_OUTPUT_PROMPTS = [
    AiEditorMutationType.INSERT_BELOW,
    AiEditorMutationType.INSERT_ABOVE,
    AiEditorMutationType.REPLACE,
    AiEditorMutationType.DISCARD,
    AICompletionType.MAKE_LONGER,
    AICompletionType.MAKE_SHORTER,
] as (AICompletionType | AiEditorMutationType)[];

export type LimboModeOutput = {
    markdown: string;
    html: string;
};

export type OptimisticOutputType = {
    markdown: string;
    from: number;
    to: number;
};
