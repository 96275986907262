import Image from "next/image";
import Dialog from "@/components/Dialog";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { ASSETS_URL } from "@/config/deployConstants";
import { themeColors } from "@/utils/themeColors";

export const DiscardPopup = ({
    isOpen,
    onDiscard,
    onCancel,
}: {
    isOpen: boolean;
    onDiscard: () => void;
    onCancel: () => void;
}) => {
    return (
        <Dialog
            onClose={onCancel}
            open={isOpen}
            PaperProps={{
                style: {
                    backgroundColor: "var(--color-background)",
                    borderRadius: "2.5rem",
                    padding: "2rem",
                    height: "18rem",
                    boxShadow: "none",
                },
            }}>
            <FlexColumn style={{ margin: "auto", padding: "0 1.5rem" }}>
                <FlexRowAlignCenter
                    className="knowtFontBold"
                    style={{ fontSize: "1.9rem", gap: "1rem", color: "var(--color-neutral-black)" }}>
                    <Image src={`${ASSETS_URL}/images/robot.png`} width={35} height={35} alt="robot" />
                    Do you want to discard what the AI wrote?
                </FlexRowAlignCenter>
                <FlexRowAlignCenter
                    className="knowtFontBold"
                    style={{ marginTop: "3rem", justifyContent: "space-between" }}>
                    <CircularRectTextButton
                        onClick={onCancel}
                        sx={{
                            backgroundColor: "#000",
                            color: themeColors.pureWhite,
                            width: "13rem",
                            height: "4rem",
                            borderRadius: "1.5rem",
                        }}>
                        Cancel
                    </CircularRectTextButton>
                    <CircularRectTextButton
                        autoFocus
                        onClick={onDiscard}
                        sx={{
                            border: "1px solid var(--color-border)",
                            backgroundColor: "var(--color-background)",
                            width: "13rem",
                            height: "4rem",
                            borderRadius: "1.5rem",
                        }}>
                        Discard
                    </CircularRectTextButton>
                </FlexRowAlignCenter>
            </FlexColumn>
        </Dialog>
    );
};
