import { capitalize } from "@/utils/stringUtils";
import { Language } from "@knowt/syncing/graphql/schema";

// TODO: remove FINMISH at some point, typo. we cant remove it for now because it will break existing users parsing who still have it
export type FLASHCARD_LANGUAGES_ENUM =
    | "SPANISH"
    | "ENGLISH"
    | "FRENCH"
    | "GERMAN"
    | "ITALIAN"
    | "LATIN"
    | "RUSSIAN"
    | "CHEMISTRY"
    | "CHINESE_PINYIN"
    | "MATH"
    | "JAPANESE"
    | "JAPANESE_ROMAJI"
    | "KOREAN"
    | "IRISH"
    | "DUTCH"
    | "VIETNAMESE"
    | "AFRIKAANS"
    | "AKAN"
    | "AKKADIAN"
    | "ALBANIAN"
    | "AMHARIC"
    | "ANGLO_SAXON"
    | "ARABIC"
    | "ARMENIAN"
    | "AZERBAIJAN"
    | "BASQUE"
    | "BELARUSIAN"
    | "BENGALI"
    | "BIHARI"
    | "BOSNIAN"
    | "BRETON"
    | "BULGARIAN"
    | "BURMESE"
    | "CATALAN"
    | "CEBUANO"
    | "CHAMORRA"
    | "CHEROKEE"
    | "CHOCTAW"
    | "COPTIC"
    | "CORSICAN"
    | "CROATIAN"
    | "CZECH"
    | "DANISH"
    | "DENE"
    | "DHIVEHI"
    | "DHOLUO"
    | "ESPERANTO"
    | "ESTONIAN"
    | "FAROESE"
    | "FILIPINO"
    | "FINNISH"
    | "FINMISH"
    | "FULA"
    | "GAELIC"
    | "GALICIAN"
    | "GEORGIAN"
    | "GOTHIC"
    | "GREEK"
    | "GUARANI"
    | "GUJARATI"
    | "HAIDA"
    | "HAITIAN_CREOLE"
    | "HAUSA"
    | "HAWAIIAN"
    | "HEBREW"
    | "HINDI"
    | "HMONG"
    | "HUNGARIAN"
    | "ICELANDIC"
    | "IGBO"
    | "ILONGGO"
    | "INDONESIAN"
    | "INTERNATIONAL_PHONETIC_ALPHABET"
    | "INUKTITUT"
    | "JAVANESE"
    | "JOLA_FONYI"
    | "KANNADA"
    | "KAZAKH"
    | "KHMER"
    | "KIKONGA"
    | "KINYARWANDA"
    | "KIOWA"
    | "KONKOW"
    | "KURDISH"
    | "KYRGYZ"
    | "LAKOTA"
    | "LAO"
    | "LATVIAN"
    | "LENAPE"
    | "LINGALA"
    | "LITHUANIAN"
    | "LUBA_KASAI"
    | "LUXEMBOURGISH"
    | "MACEDONIAN"
    | "MALAGASY"
    | "MALAY"
    | "MALAYALAM"
    | "MALTESE"
    | "MANDINKA"
    | "MAORI"
    | "MAORI_COOK_ISLAND"
    | "MARATHI"
    | "MARSHALLESE"
    | "MOHAWK"
    | "MONGOLIAN"
    | "NAHUATL"
    | "NAVAJO"
    | "NEPALI"
    | "NORWEGIAN"
    | "OCCITAN"
    | "OJIBWE"
    | "ORIYA"
    | "OROMO"
    | "OTHER"
    | "PALI"
    | "PASHTO"
    | "PERSIAN"
    | "POLISH"
    | "PORTUGUESE"
    | "PUNJABI"
    | "QUECHUA"
    | "ROMANSH"
    | "ROMANIAN"
    | "SAMOAN"
    | "SANSKRIT"
    | "SENECA"
    | "SERBIAN"
    | "SGAW_KAREN"
    | "SHAN"
    | "SINDHI"
    | "SINHALESE"
    | "SLOVAK"
    | "SLOVENIAN"
    | "SOMALI"
    | "SUNDANESE"
    | "SWAHILI"
    | "SWEDISH"
    | "SYRIAC"
    | "TAGALOG"
    | "TAJIK"
    | "TAMIL"
    | "TATAR"
    | "TELUGU"
    | "TETUM"
    | "THAI"
    | "TIBETAN"
    | "TIGRINYA"
    | "TOHONA_O_ODHAM"
    | "TONGA"
    | "TRIKI"
    | "TURKISH"
    | "TUVAN"
    | "UIGHUR"
    | "UKRAINIAN"
    | "URDU"
    | "UZBEK"
    | "WELSH"
    | "FRISIAN"
    | "WINNEBAGO"
    | "WOLOF"
    | "XHOSA"
    | "YIDDISH"
    | "YORUBA"
    | "ZULU";

export const FLASHCARD_LANGUAGES = {
    SPANISH: "SPANISH",
    FRENCH: "FRENCH",
    GERMAN: "GERMAN",
    ITALIAN: "ITALIAN",
    LATIN: "LATIN",
    RUSSIAN: "RUSSIAN",
    CHEMISTRY: "CHEMISTRY",
    CHINESE: "CHINESE",
    CHINESE_TRADITIONAL: "CHINESE_TRADITIONAL",
    CHINESE_PINYIN: "CHINESE_PINYIN",
    MATH: "MATH",
    ENGLISH: "ENGLISH",
    JAPANESE: "JAPANESE",
    JAPANESE_ROMAJI: "JAPANESE_ROMAJI",
    KOREAN: "KOREAN",
    IRISH: "IRISH",
    DUTCH: "DUTCH",
    VIETNAMESE: "VIETNAMESE",
    AFRIKAANS: "AFRIKAANS",
    AKAN: "AKAN",
    AKKADIAN: "AKKADIAN",
    ALBANIAN: "ALBANIAN",
    AMHARIC: "AMHARIC",
    ANGLO_SAXON: "ANGLO_SAXON",
    ARABIC: "ARABIC",
    ARMENIAN: "ARMENIAN",
    AZERBAIJAN: "AZERBAIJAN",
    BASQUE: "BASQUE",
    BELARUSIAN: "BELARUSIAN",
    BENGALI: "BENGALI",
    BIHARI: "BIHARI",
    BOSNIAN: "BOSNIAN",
    BRETON: "BRETON",
    BULGARIAN: "BULGARIAN",
    BURMESE: "BURMESE",
    CATALAN: "CATALAN",
    CEBUANO: "CEBUANO",
    CHAMORRA: "CHAMORRA",
    CHEROKEE: "CHEROKEE",
    CHOCTAW: "CHOCTAW",
    COPTIC: "COPTIC",
    CORSICAN: "CORSICAN",
    CROATIAN: "CROATIAN",
    CZECH: "CZECH",
    DANISH: "DANISH",
    DENE: "DENE",
    DHIVEHI: "DHIVEHI",
    DHOLUO: "DHOLUO",
    ESPERANTO: "ESPERANTO",
    ESTONIAN: "ESTONIAN",
    FAROESE: "FAROESE",
    FILIPINO: "FILIPINO",
    FINNISH: "FINNISH",
    FINMISH: "FINNISH",
    FULA: "FULA",
    GAELIC: "GAELIC",
    GALICIAN: "GALICIAN",
    GEORGIAN: "GEORGIAN",
    GOTHIC: "GOTHIC",
    GREEK: "GREEK",
    GUARANI: "GUARANI",
    GUJARATI: "GUJARATI",
    HAIDA: "HAIDA",
    HAITIAN_CREOLE: "HAITIAN_CREOLE",
    HAUSA: "HAUSA",
    HAWAIIAN: "HAWAIIAN",
    HEBREW: "HEBREW",
    HINDI: "HINDI",
    HMONG: "HMONG",
    HUNGARIAN: "HUNGARIAN",
    ICELANDIC: "ICELANDIC",
    IGBO: "IGBO",
    ILONGGO: "ILONGGO",
    INDONESIAN: "INDONESIAN",
    INTERNATIONAL_PHONETIC_ALPHABET: "INTERNATIONAL_PHONETIC_ALPHABET",
    INUKTITUT: "INUKTITUT",
    JAVANESE: "JAVANESE",
    JOLA_FONYI: "JOLA_FONYI",
    KANNADA: "KANNADA",
    KAZAKH: "KAZAKH",
    KHMER: "KHMER",
    KIKONGA: "KIKONGA",
    KINYARWANDA: "KINYARWANDA",
    KIOWA: "KIOWA",
    KONKOW: "KONKOW",
    KURDISH: "KURDISH",
    KYRGYZ: "KYRGYZ",
    LAKOTA: "LAKOTA",
    LAO: "LAO",
    LATVIAN: "LATVIAN",
    LENAPE: "LENAPE",
    LINGALA: "LINGALA",
    LITHUANIAN: "LITHUANIAN",
    LUBA_KASAI: "LUBA_KASAI",
    LUXEMBOURGISH: "LUXEMBOURGISH",
    MACEDONIAN: "MACEDONIAN",
    MALAGASY: "MALAGASY",
    MALAY: "MALAY",
    MALAYALAM: "MALAYALAM",
    MALTESE: "MALTESE",
    MANDINKA: "MANDINKA",
    MAORI: "MAORI",
    MAORI_COOK_ISLAND: "MAORI_COOK_ISLAND",
    MARATHI: "MARATHI",
    MARSHALLESE: "MARSHALLESE",
    MOHAWK: "MOHAWK",
    MONGOLIAN: "MONGOLIAN",
    NAHUATL: "NAHUATL",
    NAVAJO: "NAVAJO",
    NEPALI: "NEPALI",
    NORWEGIAN: "NORWEGIAN",
    OCCITAN: "OCCITAN",
    OJIBWE: "OJIBWE",
    ORIYA: "ORIYA",
    OROMO: "OROMO",
    OTHER: "OTHER",
    PALI: "PALI",
    PASHTO: "PASHTO",
    PERSIAN: "PERSIAN",
    POLISH: "POLISH",
    PORTUGUESE: "PORTUGUESE",
    PUNJABI: "PUNJABI",
    QUECHUA: "QUECHUA",
    ROMANSH: "ROMANSH",
    ROMANIAN: "ROMANIAN",
    SAMOAN: "SAMOAN",
    SANSKRIT: "SANSKRIT",
    SENECA: "SENECA",
    SERBIAN: "SERBIAN",
    SGAW_KAREN: "SGAW_KAREN",
    SHAN: "SHAN",
    SINDHI: "SINDHI",
    SINHALESE: "SINHALESE",
    SLOVAK: "SLOVAK",
    SLOVENIAN: "SLOVENIAN",
    SOMALI: "SOMALI",
    SUNDANESE: "SUNDANESE",
    SWAHILI: "SWAHILI",
    SWEDISH: "SWEDISH",
    SYRIAC: "SYRIAC",
    TAGALOG: "TAGALOG",
    TAJIK: "TAJIK",
    TAMIL: "TAMIL",
    TATAR: "TATAR",
    TELUGU: "TELUGU",
    TETUM: "TETUM",
    THAI: "THAI",
    TIBETAN: "TIBETAN",
    TIGRINYA: "TIGRINYA",
    TOHONA_O_ODHAM: "TOHONA_O_ODHAM",
    TONGA: "TONGA",
    TRIKI: "TRIKI",
    TURKISH: "TURKISH",
    TUVAN: "TUVAN",
    UIGHUR: "UIGHUR",
    UKRAINIAN: "UKRAINIAN",
    URDU: "URDU",
    UZBEK: "UZBEK",
    WELSH: "WELSH",
    FRISIAN: "FRISIAN",
    WINNEBAGO: "WINNEBAGO",
    WOLOF: "WOLOF",
    XHOSA: "XHOSA",
    YIDDISH: "YIDDISH",
    YORUBA: "YORUBA",
    ZULU: "ZULU",
} as { [key in Language]: Language };

export const REMOVED_LANGUAGES: Set<Language> = new Set([
    Language.CHEMISTRY,
    Language.MATH,
    Language.JAPANESE_ROMAJI,
    Language.AKKADIAN,
    Language.ANGLO_SAXON,
    Language.BIHARI,
    Language.BRETON,
    Language.CHAMORRA,
    Language.CHEROKEE,
    Language.CHOCTAW,
    Language.COPTIC,
    Language.DENE,
    Language.DHIVEHI,
    Language.DHOLUO,
    Language.FINMISH,
    Language.FULA,
    Language.GOTHIC,
    Language.HAIDA,
    Language.ILONGGO,
    Language.INTERNATIONAL_PHONETIC_ALPHABET,
    Language.INUKTITUT,
    Language.JOLA_FONYI,
    Language.KIKONGA,
    Language.KIOWA,
    Language.KONKOW,
    Language.LAKOTA,
    Language.LENAPE,
    Language.LUBA_KASAI,
    Language.MANDINKA,
    Language.MAORI_COOK_ISLAND,
    Language.MARSHALLESE,
    Language.MOHAWK,
    Language.NAHUATL,
    Language.NAVAJO,
    Language.OCCITAN,
    Language.OJIBWE,
    Language.OTHER,
    Language.PALI,
    Language.ROMANSH,
    Language.SENECA,
    Language.SGAW_KAREN,
    Language.SHAN,
    Language.SINHALESE,
    Language.SYRIAC,
    Language.TAGALOG,
    Language.TETUM,
    Language.TIBETAN,
    Language.TOHONA_O_ODHAM,
    Language.TONGA,
    Language.TRIKI,
    Language.TUVAN,
    Language.UIGHUR,
    Language.WINNEBAGO,
    Language.WOLOF,
]);

// prettier-ignore
export const ACCENT = {
    LOWER: {
        [FLASHCARD_LANGUAGES.SPANISH]: ["¡", "¿", "°", "á", "é", "í", "ñ", "ó", "ú", "ü"],
        [FLASHCARD_LANGUAGES.FRENCH]: ["à", "â", "ç", "é", "è", "ê", "ë", "î", "ï", "ô", "œ", "ù", "û", "ü"],
        [FLASHCARD_LANGUAGES.GERMAN]: ["⸚", "ä", "ö", "ß", "ü"],
        [FLASHCARD_LANGUAGES.ITALIAN]: ["à", "é", "è", "ì", "ó", "ò", "ù"],
        [FLASHCARD_LANGUAGES.LATIN]: ["ā", "æ", "ē", "ī", "ō", "œ", "ū", "ȳ"],
        [FLASHCARD_LANGUAGES.RUSSIAN]: ["á", "é", "ó", "а", "б", "в", "г", "д", "е", "ё", "ж", "з", "и", "и́", "й", "к", "л", "м", "н", "о","п", "р", "с", "т", "у", "у́", "ф", "х", "ц", "ч", "ш", "щ","ъ", "ы", "ы́", "ь", "э", "ю", "ю́", "я", "я́"],
        [FLASHCARD_LANGUAGES.CHEMISTRY]: ["°", "←", "→", "↑", "↓", "↔", "⇌", "∆", "⁺", "₊", "⁻", "₋", "∙", "⁰", "₀", "¹", "₁", "²", "₂", "³", "₃", "⁴", "₄", "⁵", "₅", "⁶", "₆", "⁷", "₇", "⁸", "₈", "⁹", "₉", "ⁿ", "ₙ", "ε"],
        [FLASHCARD_LANGUAGES.CHINESE]: [],
        [FLASHCARD_LANGUAGES.CHINESE_TRADITIONAL]: [],
        [FLASHCARD_LANGUAGES.CHINESE_PINYIN]: ["á", "à", "ǎ", "ā", "é", "è", "ě", "ē", "í", "ì", "ǐ", "ī", "ó", "ò", "ǒ", "ō", "ú", "ù", "ǔ", "ü", "ǘ", "ǜ", "ǚ", "ǖ", "ū"],
        [FLASHCARD_LANGUAGES.MATH]: ["‖", "%", "°", "←", "→", "↑", "↓", "↔", "∀", "∃", "∅", "∆", "∈", "∉", "∋", "∑", "+", "⁺", "₊", "±", "÷", "×", "=", "≠", ">", "|", "−", "⁻", "√", "∞", "∠", "∧", "∨", "∩", "∪", "∫", "∴", "∵", "∼", "≅", "≈", "≡", "≤", "≥", "⊂", "⊄", "⊃", "⊆", "⊇", "⊕", "⊗", "⊥", "⌒", "⁰", "₀", "¹", "₁", "½", "¼", "²", "₂", "³", "₃", "¾", "⁴", "₄", "⁵", "₅", "⁶", "₆", "⁷", "₇", "⁸", "₈", "⁹", "₉", "ƒ", "ⁿ", "ₙ", "α", "β", "γ", "δ", "ε", "η", "θ", "ι", "κ", "λ", "µ", "π", "σ", "ω", "Ω", "ℵ"],
        [FLASHCARD_LANGUAGES.JAPANESE]: [],
        [FLASHCARD_LANGUAGES.JAPANESE_ROMAJI]: [],
        [FLASHCARD_LANGUAGES.KOREAN]: [],
        [FLASHCARD_LANGUAGES.IRISH]: ["á", "à", "é", "è", "í", "ì", "ó", "ò", "ú", "ù"],
        [FLASHCARD_LANGUAGES.DUTCH]: ["á", "à", "â", "ä", "ç", "é", "è", "ê", "ë", "í", "ì", "î", "ï", "ó", "ò", "ô", "ö", "ú", "ù", "û", "ü", "ý", "ÿ"],
        [FLASHCARD_LANGUAGES.VIETNAMESE]: ["á", "â", "ǎ", "đ", "ê", "ô", "ơ", "ư"],
        [FLASHCARD_LANGUAGES.AFRIKAANS]: ["á", "à", "â", "ä", "ç", "é", "è", "ê", "ë", "í", "ì", "î", "ï", "ó", "ò", "ô", "ö", "ú", "ù", "û", "ü", "ý", "ÿ"],
        [FLASHCARD_LANGUAGES.AKAN]: [],
        [FLASHCARD_LANGUAGES.AKKADIAN]: ["₀", "₁", "₂", "₃", "₄", "₅", "₆", "₇", "₈", "₉", "â", "ā", "ê", "ē", "ḫ", "î", "ī", "ô", "ō", "š", "ṣ", "ṭ", "û", "ū"],
        [FLASHCARD_LANGUAGES.ALBANIAN]: [],
        [FLASHCARD_LANGUAGES.AMHARIC]: [],
        [FLASHCARD_LANGUAGES.ANGLO_SAXON]: ["æ","ð", "þ"],
        [FLASHCARD_LANGUAGES.ARABIC]: [],
        [FLASHCARD_LANGUAGES.ARMENIAN]: [],
        [FLASHCARD_LANGUAGES.AZERBAIJAN]: [],
        [FLASHCARD_LANGUAGES.BASQUE]: [],
        [FLASHCARD_LANGUAGES.BELARUSIAN]: [],
        [FLASHCARD_LANGUAGES.BENGALI]: [],
        [FLASHCARD_LANGUAGES.BIHARI]: [],
        [FLASHCARD_LANGUAGES.BOSNIAN]: ["ć", "č", "đ", "š", "ž"],
        [FLASHCARD_LANGUAGES.BRETON]: [],
        [FLASHCARD_LANGUAGES.BULGARIAN]: ["а", "б", "в", "г", "д", "е", "ж", "з", "и", "й", "к", "л", "м", "н", "о", "п", "р", "с", "т", "у", "ф", "х", "ц", "ч", "ш", "щ", "ъ", "ь", "ю", "я"],
        [FLASHCARD_LANGUAGES.BURMESE]: [],
        [FLASHCARD_LANGUAGES.CATALAN]: [],
        [FLASHCARD_LANGUAGES.CEBUANO]: [],
        [FLASHCARD_LANGUAGES.CHAMORRA]: [],
        [FLASHCARD_LANGUAGES.CHEROKEE]: [],
        [FLASHCARD_LANGUAGES.CHOCTAW]: [],
        [FLASHCARD_LANGUAGES.COPTIC]: [],
        [FLASHCARD_LANGUAGES.CORSICAN]: [],
        [FLASHCARD_LANGUAGES.CROATIAN]: ["ć", "č", "đ", "š"],
        [FLASHCARD_LANGUAGES.CZECH]: ["á", "č", "ď", "é", "ě", "í", "ň", "ó", "p", "ř", "š", "ť", "ú", "ů", "ý", "ž"],
        [FLASHCARD_LANGUAGES.DANISH]: ["å", "æ", "ø"],
        [FLASHCARD_LANGUAGES.DENE]: [],
        [FLASHCARD_LANGUAGES.DHIVEHI]: [],
        [FLASHCARD_LANGUAGES.DHOLUO]: [],
        [FLASHCARD_LANGUAGES.ESPERANTO]: [],
        [FLASHCARD_LANGUAGES.ESTONIAN]: [],
        [FLASHCARD_LANGUAGES.FAROESE]: [],
        [FLASHCARD_LANGUAGES.FILIPINO]: [],
        [FLASHCARD_LANGUAGES.FINNISH]: ["å", "ä", "ö"],
        [FLASHCARD_LANGUAGES.FINMISH]: ["å", "ä", "ö"],
        [FLASHCARD_LANGUAGES.FRISIAN]: [],
        [FLASHCARD_LANGUAGES.FULA]: [],
        [FLASHCARD_LANGUAGES.GAELIC]: ["á", "à", "é", "è", "í", "ì", "ó", "ò", "ú", "ù"],
        [FLASHCARD_LANGUAGES.GALICIAN]: [],
        [FLASHCARD_LANGUAGES.GEORGIAN]: [],
        [FLASHCARD_LANGUAGES.GOTHIC]: [],
        [FLASHCARD_LANGUAGES.GREEK]: ["α", "β", "γ", "δ", "ε", "ζ", "η", "θ", "ι", "κ", "λ", "μ", "ν", "ξ", "ο", "π", "ρ", "σ", "ς", "τ", "υ", "φ", "χ", "ψ", "ω"],
        [FLASHCARD_LANGUAGES.GUARANI]: [],
        [FLASHCARD_LANGUAGES.GUJARATI]: [],
        [FLASHCARD_LANGUAGES.HAIDA]: ["g̱", "ḵ", "x̱"],
        [FLASHCARD_LANGUAGES.HAITIAN_CREOLE]: [],
        [FLASHCARD_LANGUAGES.HAUSA]: [],
        [FLASHCARD_LANGUAGES.HAWAIIAN]: ["ā", "ē", "ī", "ō", "ū"],
        [FLASHCARD_LANGUAGES.HEBREW]: [],
        [FLASHCARD_LANGUAGES.HINDI]: [],
        [FLASHCARD_LANGUAGES.HMONG]: [],
        [FLASHCARD_LANGUAGES.HUNGARIAN]: ["á", "é", "í", "ó", "ö", "ő", "ú", "ü", "ű"],
        [FLASHCARD_LANGUAGES.ICELANDIC]: ["á", "æ", "ð", "é", "í", "ó", "ö", "ø", "ú", "ý", "þ"],
        [FLASHCARD_LANGUAGES.IGBO]: [],
        [FLASHCARD_LANGUAGES.ILONGGO]: [],
        [FLASHCARD_LANGUAGES.INDONESIAN]: [],
        [FLASHCARD_LANGUAGES.INTERNATIONAL_PHONETIC_ALPHABET]: [],
        [FLASHCARD_LANGUAGES.INUKTITUT]: [],
        [FLASHCARD_LANGUAGES.JAVANESE]: [],
        [FLASHCARD_LANGUAGES.JOLA_FONYI]: ["á", "é", "í", "ñ", "Ŋ", "ó", "ú"],
        [FLASHCARD_LANGUAGES.KANNADA]: [],
        [FLASHCARD_LANGUAGES.KAZAKH]: [],
        [FLASHCARD_LANGUAGES.KHMER]: [],
        [FLASHCARD_LANGUAGES.KIKONGA]: [],
        [FLASHCARD_LANGUAGES.KINYARWANDA]: [],
        [FLASHCARD_LANGUAGES.KIOWA]: [],
        [FLASHCARD_LANGUAGES.KONKOW]: [],
        [FLASHCARD_LANGUAGES.KURDISH]: ["ç", "é", "ê", "í", "î", "ş", "ú", "û"],
        [FLASHCARD_LANGUAGES.KYRGYZ]: [],
        [FLASHCARD_LANGUAGES.LAKOTA]: ["á", "ą", "ą́", "č", "é", "ǧ", "ȟ", "í", "į", "ŋ", "ó", "š", "ú", "ų", "ž","ʼ"],
        [FLASHCARD_LANGUAGES.LAO]: [],
        [FLASHCARD_LANGUAGES.LATVIAN]: [],
        [FLASHCARD_LANGUAGES.LENAPE]: [],
        [FLASHCARD_LANGUAGES.LINGALA]: [],
        [FLASHCARD_LANGUAGES.LITHUANIAN]: [],
        [FLASHCARD_LANGUAGES.LUBA_KASAI]: [],
        [FLASHCARD_LANGUAGES.LUXEMBOURGISH]: [],
        [FLASHCARD_LANGUAGES.MACEDONIAN]: [],
        [FLASHCARD_LANGUAGES.MALAGASY]: [],
        [FLASHCARD_LANGUAGES.MALAY]: [],
        [FLASHCARD_LANGUAGES.MALAYALAM]: [],
        [FLASHCARD_LANGUAGES.MALTESE]: [],
        [FLASHCARD_LANGUAGES.MANDINKA]: [],
        [FLASHCARD_LANGUAGES.MAORI]: ["ā", "ē", "ʻ", "ī", "ō", "ū"],
        [FLASHCARD_LANGUAGES.MAORI_COOK_ISLAND]: ["ā", "ē", "ʻ", "ī", "ō", "ū"],
        [FLASHCARD_LANGUAGES.MARATHI]: [],
        [FLASHCARD_LANGUAGES.MARSHALLESE]: [],
        [FLASHCARD_LANGUAGES.MOHAWK]: [],
        [FLASHCARD_LANGUAGES.MONGOLIAN]: [],
        [FLASHCARD_LANGUAGES.NAHUATL]: [],
        [FLASHCARD_LANGUAGES.NAVAJO]: [],
        [FLASHCARD_LANGUAGES.NEPALI]: [],
        [FLASHCARD_LANGUAGES.NORWEGIAN]: ["å", "æ", "ø"],
        [FLASHCARD_LANGUAGES.OCCITAN]: [],
        [FLASHCARD_LANGUAGES.OJIBWE]: [],
        [FLASHCARD_LANGUAGES.ORIYA]: [],
        [FLASHCARD_LANGUAGES.OROMO]: [],
        [FLASHCARD_LANGUAGES.OTHER]: [],
        [FLASHCARD_LANGUAGES.PALI]: [],
        [FLASHCARD_LANGUAGES.PASHTO]: [],
        [FLASHCARD_LANGUAGES.PERSIAN]: [],
        [FLASHCARD_LANGUAGES.POLISH]: ["ą", "ć", "ę", "ł", "ń", "ó", "ś", "ź", "ż"],
        [FLASHCARD_LANGUAGES.PORTUGUESE]: ["á", "à", "â", "ã", "ç", "é", "ê", "í", "ó", "ô", "õ", "ú", "ü"],
        [FLASHCARD_LANGUAGES.PUNJABI]: [],
        [FLASHCARD_LANGUAGES.QUECHUA]: [],
        [FLASHCARD_LANGUAGES.ROMANSH]: [],
        [FLASHCARD_LANGUAGES.ROMANIAN]: ["ă", "â", "î", "ş", "ș", "ţ", "ț"],
        [FLASHCARD_LANGUAGES.SAMOAN]: [],
        [FLASHCARD_LANGUAGES.SANSKRIT]: [],
        [FLASHCARD_LANGUAGES.SENECA]: [],
        [FLASHCARD_LANGUAGES.SERBIAN]: ["ć", "č", "đ", "š", "ž"],
        [FLASHCARD_LANGUAGES.SGAW_KAREN]: [],
        [FLASHCARD_LANGUAGES.SHAN]: [],
        [FLASHCARD_LANGUAGES.SINDHI]: [],
        [FLASHCARD_LANGUAGES.SINHALESE]: [],
        [FLASHCARD_LANGUAGES.SLOVAK]: ["á", "ä", "č", "é", "ě", "í", "ĺ", "ľ", "ň", "ó", "ô", "ŕ", "š", "ť", "ú", "ý", "ž"],
        [FLASHCARD_LANGUAGES.SLOVENIAN]: [],
        [FLASHCARD_LANGUAGES.SOMALI]: [],
        [FLASHCARD_LANGUAGES.SUNDANESE]: [],
        [FLASHCARD_LANGUAGES.SWAHILI]: [],
        [FLASHCARD_LANGUAGES.SWEDISH]: ["å", "ä", "ö"],
        [FLASHCARD_LANGUAGES.SYRIAC]: [],
        [FLASHCARD_LANGUAGES.TAGALOG]: [],
        [FLASHCARD_LANGUAGES.TAJIK]: [],
        [FLASHCARD_LANGUAGES.TAMIL]: [],
        [FLASHCARD_LANGUAGES.TATAR]: [],
        [FLASHCARD_LANGUAGES.TELUGU]: [],
        [FLASHCARD_LANGUAGES.TETUM]: ["á", "é", "í", "ñ", "ó", "ú"],
        [FLASHCARD_LANGUAGES.THAI]: [],
        [FLASHCARD_LANGUAGES.TIBETAN]: [],
        [FLASHCARD_LANGUAGES.TIGRINYA]: [],
        [FLASHCARD_LANGUAGES.TOHONA_O_ODHAM]: ["ă", "ḍ", "ĕ", "ĭ", "ñ", "ŋ", "ŏ", "ṣ", "ŭ"],
        [FLASHCARD_LANGUAGES.TONGA]: [],
        [FLASHCARD_LANGUAGES.TRIKI]: [],
        [FLASHCARD_LANGUAGES.TURKISH]: ["ç", "ğ", "ı", "ö", "ş", "ü" ],
        [FLASHCARD_LANGUAGES.TUVAN]: ["а", "б", "в", "г", "д", "е", "ё", "ж", "з", "и", "й", "к", "л", "м", "н", "ң", "о", "ө", "п", "р", "с", "т", "у", "ү", "ф", "х", "ц", "ч", "ш", "щ", "ъ", "ы", "ь", "э", "ю", "я"],
        [FLASHCARD_LANGUAGES.UIGHUR]: [],
        [FLASHCARD_LANGUAGES.UKRAINIAN]: [],
        [FLASHCARD_LANGUAGES.URDU]: [],
        [FLASHCARD_LANGUAGES.UZBEK]: [],
        [FLASHCARD_LANGUAGES.WELSH]: [],
        [FLASHCARD_LANGUAGES.WINNEBAGO]: [],
        [FLASHCARD_LANGUAGES.WOLOF]: [],
        [FLASHCARD_LANGUAGES.XHOSA]: [],
        [FLASHCARD_LANGUAGES.YIDDISH]: [],
        [FLASHCARD_LANGUAGES.YORUBA]: [],
        [FLASHCARD_LANGUAGES.ZULU]: []
    },
    UPPER: {
        [FLASHCARD_LANGUAGES.SPANISH]: ["¡", "¿", "°", "Á", "É", "Í", "Ñ", "Ó", "Ú", "Ü"],
        [FLASHCARD_LANGUAGES.FRENCH]: ["À", "Â", "Ç", "É", "È", "Ê", "Ë", "Î", "Ï", "Ô", "Œ", "Ù", "Û", "Ü"],
        [FLASHCARD_LANGUAGES.GERMAN]: ["⸚", "Ä", "Ö", "SS", "Ü"],
        [FLASHCARD_LANGUAGES.ITALIAN]: ["À", "É", "È", "Ì", "Ó", "Ò", "Ù"],
        [FLASHCARD_LANGUAGES.LATIN]: ["Ā", "Æ", "Ē", "Ī", "Ō", "Œ", "Ū", "Ȳ"],
        [FLASHCARD_LANGUAGES.RUSSIAN]: ["Á", "É", "Ó", "А", "Б", "В", "Г", "Д", "Е", "Ё", "Ж", "З", "И", "И́", "Й", "К", "Л", "М", "Н", "О","П", "Р", "С", "Т", "У", "У́", "Ф", "Х", "Ц", "Ч", "Ш", "Щ","Ъ", "Ы", "Ы́", "Ь", "Э", "Ю", "Ю́", "Я", "Я́"],
        [FLASHCARD_LANGUAGES.CHEMISTRY]: ["°", "←", "→", "↑", "↓", "↔", "⇌", "∆", "⁺", "₊", "⁻", "₋", "∙", "⁰", "₀", "¹", "₁", "²", "₂", "³", "₃", "⁴", "₄", "⁵", "₅", "⁶", "₆", "⁷", "₇", "⁸", "₈", "⁹", "₉", "ⁿ", "ₙ", "Ε"],
        [FLASHCARD_LANGUAGES.CHINESE_PINYIN]: ["Á", "À", "Ǎ", "Ā", "É", "È", "Ě", "Ē", "Í", "Ì", "Ǐ", "Ī", "Ó", "Ò", "Ǒ", "Ō", "Ú", "Ù", "Ǔ", "Ü", "Ǘ", "Ǜ", "Ǚ", "Ǖ", "Ū"],
        [FLASHCARD_LANGUAGES.MATH]: ["‖", "%", "°", "←", "→", "↑", "↓", "↔", "∀", "∃", "∅", "∆", "∈", "∉", "∋", "∑", "+", "⁺", "₊", "±", "÷", "×", "=", "≠", ">", "|", "−", "⁻", "√", "∞", "∠", "∧", "∨", "∩", "∪", "∫", "∴", "∵", "∼", "≅", "≈", "≡", "≤", "≥", "⊂", "⊄", "⊃", "⊆", "⊇", "⊕", "⊗", "⊥", "⌒", "⁰", "₀", "¹", "₁", "½", "¼", "²", "₂", "³", "₃", "¾", "⁴", "₄", "⁵", "₅", "⁶", "₆", "⁷", "₇", "⁸", "₈", "⁹", "₉", "Ƒ", "ⁿ", "ₙ", "Α", "Β", "Γ", "Δ", "Ε", "Η", "Θ", "Ι", "Κ", "Λ", "Μ", "Π", "Σ", "Ω", "Ω", "ℵ"],
        [FLASHCARD_LANGUAGES.JAPANESE]: [],
        [FLASHCARD_LANGUAGES.JAPANESE_ROMAJI]: [],
        [FLASHCARD_LANGUAGES.KOREAN]: [],
        [FLASHCARD_LANGUAGES.IRISH]: ["Á", "À", "É", "È", "Í", "Ì", "Ó", "Ò", "Ú", "Ù"],
        [FLASHCARD_LANGUAGES.DUTCH]: ["Á", "À", "Â", "Ä", "Ç", "É", "È", "Ê", "Ë", "Í", "Ì", "Î", "Ï", "Ó", "Ò", "Ô", "Ö", "Ú", "Ù", "Û", "Ü", "Ý", "Ÿ"],
        [FLASHCARD_LANGUAGES.VIETNAMESE]: ["Á", "Â", "Ǎ", "Đ", "Ê", "Ô", "Ơ", "Ư"],
        [FLASHCARD_LANGUAGES.AFRIKAANS]: ["Á", "À", "Â", "Ä", "Ç", "É", "È", "Ê", "Ë", "Í", "Ì", "Î", "Ï", "Ó", "Ò", "Ô", "Ö", "Ú", "Ù", "Û", "Ü", "Ý", "Ÿ"],
        [FLASHCARD_LANGUAGES.AKAN]: [],
        [FLASHCARD_LANGUAGES.AKKADIAN]: ["₀", "₁", "₂", "₃", "₄", "₅", "₆", "₇", "₈", "₉", "Â", "Ā", "Ê", "Ē", "Ḫ", "Î", "Ī", "Ô", "Ō", "Š", "Ṣ", "Ṭ", "Û", "Ū"],
        [FLASHCARD_LANGUAGES.ALBANIAN]: [],
        [FLASHCARD_LANGUAGES.AMHARIC]: [],
        [FLASHCARD_LANGUAGES.ANGLO_SAXON]: ["Æ","Ð", "Þ"],
        [FLASHCARD_LANGUAGES.ARABIC]: [],
        [FLASHCARD_LANGUAGES.ARMENIAN]: [],
        [FLASHCARD_LANGUAGES.AZERBAIJAN]: [],
        [FLASHCARD_LANGUAGES.BASQUE]: [],
        [FLASHCARD_LANGUAGES.BELARUSIAN]: [],
        [FLASHCARD_LANGUAGES.BENGALI]: [],
        [FLASHCARD_LANGUAGES.BIHARI]: [],
        [FLASHCARD_LANGUAGES.BOSNIAN]: ["Ć", "Č", "Đ", "Š", "Ž"],
        [FLASHCARD_LANGUAGES.BRETON]: [],
        [FLASHCARD_LANGUAGES.BULGARIAN]: ["А", "Б", "В", "Г", "Д", "Е", "Ж", "З", "И", "Й", "К", "Л", "М", "Н", "О", "П", "Р", "С", "Т", "У", "Ф", "Х", "Ц", "Ч", "Ш", "Щ", "Ъ", "Ь", "Ю", "Я"],
        [FLASHCARD_LANGUAGES.BURMESE]: [],
        [FLASHCARD_LANGUAGES.CATALAN]: [],
        [FLASHCARD_LANGUAGES.CEBUANO]: [],
        [FLASHCARD_LANGUAGES.CHAMORRA]: [],
        [FLASHCARD_LANGUAGES.CHEROKEE]: [],
        [FLASHCARD_LANGUAGES.CHOCTAW]: [],
        [FLASHCARD_LANGUAGES.COPTIC]: [],
        [FLASHCARD_LANGUAGES.CORSICAN]: [],
        [FLASHCARD_LANGUAGES.CROATIAN]: ["Ć", "Č", "Đ", "Š"],
        [FLASHCARD_LANGUAGES.CZECH]: ["Á", "Č", "Ď", "É", "Ě", "Í", "Ň", "Ó", "P", "Ř", "Š", "Ť", "Ú", "Ů", "Ý", "Ž"],
        [FLASHCARD_LANGUAGES.DANISH]: ["Å", "Æ", "Ø"],
        [FLASHCARD_LANGUAGES.DENE]: [],
        [FLASHCARD_LANGUAGES.DHIVEHI]: [],
        [FLASHCARD_LANGUAGES.DHOLUO]: [],
        [FLASHCARD_LANGUAGES.ESPERANTO]: [],
        [FLASHCARD_LANGUAGES.ESTONIAN]: [],
        [FLASHCARD_LANGUAGES.FAROESE]: [],
        [FLASHCARD_LANGUAGES.FILIPINO]: [],
        [FLASHCARD_LANGUAGES.FINNISH]: ["Å", "Ä", "Ö"],
        [FLASHCARD_LANGUAGES.FRISIAN]: [],
        [FLASHCARD_LANGUAGES.FULA]: [],
        [FLASHCARD_LANGUAGES.GAELIC]: ["Á", "À", "É", "È", "Í", "Ì", "Ó", "Ò", "Ú", "Ù"],
        [FLASHCARD_LANGUAGES.GALICIAN]: [],
        [FLASHCARD_LANGUAGES.GEORGIAN]: [],
        [FLASHCARD_LANGUAGES.GOTHIC]: [],
        [FLASHCARD_LANGUAGES.GREEK]: ["Α", "Β", "Γ", "Δ", "Ε", "Ζ", "Η", "Θ", "Ι", "Κ", "Λ", "Μ", "Ν", "Ξ", "Ο", "Π", "Ρ", "Σ", "Σ", "Τ", "Υ", "Φ", "Χ", "Ψ", "Ω"],
        [FLASHCARD_LANGUAGES.GUARANI]: [],
        [FLASHCARD_LANGUAGES.GUJARATI]: [],
        [FLASHCARD_LANGUAGES.HAIDA]: ["G̱", "Ḵ", "X̱"],
        [FLASHCARD_LANGUAGES.HAITIAN_CREOLE]: [],
        [FLASHCARD_LANGUAGES.HAUSA]: [],
        [FLASHCARD_LANGUAGES.HAWAIIAN]: ["Ā", "Ē", "Ī", "Ō", "Ū"],
        [FLASHCARD_LANGUAGES.HEBREW]: [],
        [FLASHCARD_LANGUAGES.HINDI]: [],
        [FLASHCARD_LANGUAGES.HMONG]: [],
        [FLASHCARD_LANGUAGES.HUNGARIAN]: ["Á", "É", "Í", "Ó", "Ö", "Ő", "Ú", "Ü", "Ű"],
        [FLASHCARD_LANGUAGES.ICELANDIC]: ["Á", "Æ", "Ð", "É", "Í", "Ó", "Ö", "Ø", "Ú", "Ý", "Þ"],
        [FLASHCARD_LANGUAGES.IGBO]: [],
        [FLASHCARD_LANGUAGES.ILONGGO]: [],
        [FLASHCARD_LANGUAGES.INDONESIAN]: [],
        [FLASHCARD_LANGUAGES.INTERNATIONAL_PHONETIC_ALPHABET]: [],
        [FLASHCARD_LANGUAGES.INUKTITUT]: [],
        [FLASHCARD_LANGUAGES.JAVANESE]: [],
        [FLASHCARD_LANGUAGES.JOLA_FONYI]: ["Á", "É", "Í", "Ñ", "Ŋ", "Ó", "Ú"],
        [FLASHCARD_LANGUAGES.KANNADA]: [],
        [FLASHCARD_LANGUAGES.KAZAKH]: [],
        [FLASHCARD_LANGUAGES.KHMER]: [],
        [FLASHCARD_LANGUAGES.KIKONGA]: [],
        [FLASHCARD_LANGUAGES.KINYARWANDA]: [],
        [FLASHCARD_LANGUAGES.KIOWA]: [],
        [FLASHCARD_LANGUAGES.KONKOW]: [],
        [FLASHCARD_LANGUAGES.KURDISH]: ["Ç", "É", "Ê", "Í", "Î", "Ş", "Ú", "Û"],
        [FLASHCARD_LANGUAGES.KYRGYZ]: [],
        [FLASHCARD_LANGUAGES.LAKOTA]: ["Á", "Ą", "Ą́", "Č", "É", "Ǧ", "Ȟ", "Í", "Į", "Ŋ", "Ó", "Š", "Ú", "Ų", "Ž","ʼ"],
        [FLASHCARD_LANGUAGES.LAO]: [],
        [FLASHCARD_LANGUAGES.LATVIAN]: [],
        [FLASHCARD_LANGUAGES.LENAPE]: [],
        [FLASHCARD_LANGUAGES.LINGALA]: [],
        [FLASHCARD_LANGUAGES.LITHUANIAN]: [],
        [FLASHCARD_LANGUAGES.LUBA_KASAI]: [],
        [FLASHCARD_LANGUAGES.LUXEMBOURGISH]: [],
        [FLASHCARD_LANGUAGES.MACEDONIAN]: [],
        [FLASHCARD_LANGUAGES.MALAGASY]: [],
        [FLASHCARD_LANGUAGES.MALAY]: [],
        [FLASHCARD_LANGUAGES.MALAYALAM]: [],
        [FLASHCARD_LANGUAGES.MALTESE]: [],
        [FLASHCARD_LANGUAGES.MANDINKA]: [],
        [FLASHCARD_LANGUAGES.MAORI]: ["Ā", "Ē", "ʻ", "Ī", "Ō", "Ū"],
        [FLASHCARD_LANGUAGES.MAORI_COOK_ISLAND]: ["Ā", "Ē", "ʻ", "Ī", "Ō", "Ū"],
        [FLASHCARD_LANGUAGES.MARATHI]: [],
        [FLASHCARD_LANGUAGES.MARSHALLESE]: [],
        [FLASHCARD_LANGUAGES.MOHAWK]: [],
        [FLASHCARD_LANGUAGES.MONGOLIAN]: [],
        [FLASHCARD_LANGUAGES.NAHUATL]: [],
        [FLASHCARD_LANGUAGES.NAVAJO]: [],
        [FLASHCARD_LANGUAGES.NEPALI]: [],
        [FLASHCARD_LANGUAGES.NORWEGIAN]: ["Å", "Æ", "Ø"],
        [FLASHCARD_LANGUAGES.OCCITAN]: [],
        [FLASHCARD_LANGUAGES.OJIBWE]: [],
        [FLASHCARD_LANGUAGES.ORIYA]: [],
        [FLASHCARD_LANGUAGES.OROMO]: [],
        [FLASHCARD_LANGUAGES.OTHER]: [],
        [FLASHCARD_LANGUAGES.PALI]: [],
        [FLASHCARD_LANGUAGES.PASHTO]: [],
        [FLASHCARD_LANGUAGES.PERSIAN]: [],
        [FLASHCARD_LANGUAGES.POLISH]: ["Ą", "Ć", "Ę", "Ł", "Ń", "Ó", "Ś", "Ź", "Ż"],
        [FLASHCARD_LANGUAGES.PORTUGUESE]: ["Á", "À", "Â", "Ã", "Ç", "É", "Ê", "Í", "Ó", "Ô", "Õ", "Ú", "Ü"],
        [FLASHCARD_LANGUAGES.PUNJABI]: [],
        [FLASHCARD_LANGUAGES.QUECHUA]: [],
        [FLASHCARD_LANGUAGES.ROMANSH]: [],
        [FLASHCARD_LANGUAGES.ROMANIAN]: ["Ă", "Â", "Î", "Ş", "Ș", "Ţ", "Ț"],
        [FLASHCARD_LANGUAGES.SAMOAN]: [],
        [FLASHCARD_LANGUAGES.SANSKRIT]: [],
        [FLASHCARD_LANGUAGES.SENECA]: [],
        [FLASHCARD_LANGUAGES.SERBIAN]: ["Ć", "Č", "Đ", "Š", "Ž"],
        [FLASHCARD_LANGUAGES.SGAW_KAREN]: [],
        [FLASHCARD_LANGUAGES.SHAN]: [],
        [FLASHCARD_LANGUAGES.SINDHI]: [],
        [FLASHCARD_LANGUAGES.SINHALESE]: [],
        [FLASHCARD_LANGUAGES.SLOVAK]: ["Á", "Ä", "Č", "É", "Ě", "Í", "Ĺ", "Ľ", "Ň", "Ó", "Ô", "Ŕ", "Š", "Ť", "Ú", "Ý", "Ž"],
        [FLASHCARD_LANGUAGES.SLOVENIAN]: [],
        [FLASHCARD_LANGUAGES.SOMALI]: [],
        [FLASHCARD_LANGUAGES.SUNDANESE]: [],
        [FLASHCARD_LANGUAGES.SWAHILI]: [],
        [FLASHCARD_LANGUAGES.SWEDISH]: ["Å", "Ä", "Ö"],
        [FLASHCARD_LANGUAGES.SYRIAC]: [],
        [FLASHCARD_LANGUAGES.TAGALOG]: [],
        [FLASHCARD_LANGUAGES.TAJIK]: [],
        [FLASHCARD_LANGUAGES.TAMIL]: [],
        [FLASHCARD_LANGUAGES.TATAR]: [],
        [FLASHCARD_LANGUAGES.TELUGU]: [],
        [FLASHCARD_LANGUAGES.TETUM]: ["Á", "É", "Í", "Ñ", "Ó", "Ú"],
        [FLASHCARD_LANGUAGES.THAI]: [],
        [FLASHCARD_LANGUAGES.TIBETAN]: [],
        [FLASHCARD_LANGUAGES.TIGRINYA]: [],
        [FLASHCARD_LANGUAGES.TOHONA_O_ODHAM]: ["Ă", "Ḍ", "Ĕ", "Ĭ", "Ñ", "Ŋ", "Ŏ", "Ṣ", "Ŭ"],
        [FLASHCARD_LANGUAGES.TONGA]: [],
        [FLASHCARD_LANGUAGES.TRIKI]: [],
        [FLASHCARD_LANGUAGES.TURKISH]: ["Ç", "Ğ", "I", "Ö", "Ş", "Ü" ],
        [FLASHCARD_LANGUAGES.TUVAN]: ["А", "Б", "В", "Г", "Д", "Е", "Ё", "Ж", "З", "И", "Й", "К", "Л", "М", "Н", "Ң", "О", "Ө", "П", "Р", "С", "Т", "У", "Ү", "Ф", "Х", "Ц", "Ч", "Ш", "Щ", "Ъ", "Ы", "Ь", "Э", "Ю", "Я"],
        [FLASHCARD_LANGUAGES.UIGHUR]: [],
        [FLASHCARD_LANGUAGES.UKRAINIAN]: [],
        [FLASHCARD_LANGUAGES.URDU]: [],
        [FLASHCARD_LANGUAGES.UZBEK]: [],
        [FLASHCARD_LANGUAGES.WELSH]: [],
        [FLASHCARD_LANGUAGES.WINNEBAGO]: [],
        [FLASHCARD_LANGUAGES.WOLOF]: [],
        [FLASHCARD_LANGUAGES.XHOSA]: [],
        [FLASHCARD_LANGUAGES.YIDDISH]: [],
        [FLASHCARD_LANGUAGES.YORUBA]: [],
        [FLASHCARD_LANGUAGES.ZULU]: []
    }
};

export const getDisplayedLanguageText = language => {
    switch (language) {
        case FLASHCARD_LANGUAGES.CHINESE:
            return "Chinese (Simplified)";
        case FLASHCARD_LANGUAGES.CHINESE_TRADITIONAL:
            return "Chinese (Traditional)";
        case FLASHCARD_LANGUAGES.CHINESE_PINYIN:
            return "Chinese (Pinyin)";
        case FLASHCARD_LANGUAGES.JAPANESE_ROMAJI:
            return "Japanese (Romaji)";
        case FLASHCARD_LANGUAGES.HAITIAN_CREOLE:
            return "Haitian Creole";
        case FLASHCARD_LANGUAGES.INTERNATIONAL_PHONETIC_ALPHABET:
            return "International Phonetic Alphabet";
        case FLASHCARD_LANGUAGES.MAORI_COOK_ISLAND:
            return "Maori (Cook Island)";
        case FLASHCARD_LANGUAGES.SGAW_KAREN:
            return "S'gaw Karen";
        case FLASHCARD_LANGUAGES.TOHONA_O_ODHAM:
            return "Tohono Oʼodham";
        case FLASHCARD_LANGUAGES.ANGLO_SAXON:
            return "Anglo-Saxon";
        case FLASHCARD_LANGUAGES.LUBA_KASAI:
            return "Luba-Kasai";
        case FLASHCARD_LANGUAGES.JOLA_FONYI:
            return "Jola_Fonyi";

        default:
            return capitalize(language);
    }
};
