import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";

const Separator = () => {
    return (
        <div
            style={{
                alignSelf: "stretch",
                margin: spacing.XXS,
                width: 1.5,
                backgroundColor: themeColors.neutral3,
                opacity: 0.1,
                borderRadius: 2,
            }}
        />
    );
};

export default Separator;
